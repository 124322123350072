import './App.css';
import img from "./OT.png"
import photo1 from "./images/air.jpg"
import photo2 from "./images/pump.jpg"
import photo3 from "./images/uasb.jpg"
import palmtrees from "./palm.jpg"
import {useState, useEffect, useRef} from "react"

function App() {
  
const [thephoto, setThePhoto] = useState(photo2)
const homebutton = useRef();
const [intro, setIntro] = useState()
const [isActive, setIsActive] = useState(1)

function Header(){
  return(
    <div className="header">
<img alt="logo" src={img}  height="50" onClick={clickHome} style={{cursor:"pointer"}} />  
Oiltek International dot NL
      </div>
  )}

function Photoprocess(e){
  console.log(e.target.name)
  const aPhoto = e.target.src;
  setThePhoto(aPhoto)
}

useEffect(()=>{
  setIntro(<TheGallery value={thephoto} />)
},[thephoto])

function TheGallery({value}){
  return(
    <>
    <div className="tablephotos ">
        <img className="photo" alt="SBR" src={photo1} onMouseOver={Photoprocess} name="photo1"/>
        <img className="photo" alt="pump" src={photo2} onMouseOver={Photoprocess} name="photo2" />
        <img className="photo" alt="UASB" src={photo3} onMouseOver={Photoprocess} name="photo3"/>
    </div>
    <div>
    <img alt="large photo" className="photolarge" src= {value} />
    </div>
    </>
  )}

function Backdrop(){
  return(
    <>
    <div className="container1">
          <div className="backdrop">
                <img alt="backdrop" className="backdroppic" src={palmtrees} width="100%" height="400px"  />
                <div className="backdroptext2">
                    <div >Refining Vegetable Oil Solutions</div>
                    <div>Leader in Refining</div>
                </div>
          </div>
    </div>
<div className = "engineering">Refining Edible Oils: *Palm Oil   *Soya Oil   *Rapeseed Oil   *Corn Oil   *Conola Oil</div>
<div className="container2">
  <div className="highlights">
    Recent Higlights in the Edible Oil Industry:
    <p>Launching dot NL website</p>
  </div>
  <div className="services">Services: 
<p>Our service promises are:</p>
<p>1) With vast experience our Accuracy is top notch</p>
<p>2) We Deliver on time every time</p>
<p>3) Reputation is key to our progress</p>
<p>4) Alwyas there whenever needed, our Technical Support know how.</p>
  </div>
</div>
<div className="container4">
Key Business:
<div className="key">
<div className="eng">Refinery</div>
<div className="eng">Renewable Energy</div>
<div className="eng">Waste Treatment</div>
</div>
</div>
</>
  )}

function gotoWWF(){
  console.log("wwf")
  return(
     setIntro(    <>
      <h1>WWF Palm OIL</h1><div><embed  src="https://www.wwf.sg/sustainability-circular-economy/sustainable-palm-oil/"  width="90%" height="900px" />  </div>  </>)
  )}

function gotoRSPO(){
  console.log("rspo")
  return(
    setIntro(  <>
      <h1>Palm OIL SUSTAINABILITY</h1><div><embed  src="https://rspo.org"  width="90%" height="900px" />  </div> </>)
  )}

function AboutUs(){
  return(
    <>
    <div className = "palmoil">
    <div><h2>About Palm Oil</h2></div>
<div>Palm Oil is the most efficient edible oil plant to produce. It is nearly 10 times more efficient than its closest competing oil: Soy</div>
<div className = "soy">
<img alt="https://www.wwf.org.uk" src="https://www.wwf.org.uk/sites/default/files/styles/content_slide_image/public/2018-11/comparison%20palm%20oil%20and%20other%20crops.jpg?h=6ba32c4a&itok=sIPFwkTZ" width="50%" height="600px"/>
<embed src="https://www.gro-intelligence.com/insights/palm-vs-soy-oil-price-disparity-likely-to-increase-in-2023"  width="50%"/>
</div>
<div>
<div className='WWF'>
<div onClick={gotoWWF} style={{cursor: 'pointer'}}> <u>WWF Palm Oil Sustainability</u></div>
  </div>
  </div>
  <div>Moreover it is the cheapest oil to produce as shown in the video below</div>
  <iframe width="650" height="380" src="https://www.youtube.com/embed/vvKgnRPThKI" title="Why Palm Oil Is So Cheap" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
    </div>
<div className='rspo'>
  <img alt="https://rspo.org" src='https://rspo.org/wp-content/uploads/rspo-who-we-are-driving-change-with-sustainable-palm-oil.jpg' height="300px" />
  <div onClick={gotoRSPO} style={{cursor: 'pointer'}}> <u>Roundtable on Sustainable Palm Oil</u></div>
</div>
  </>
  )}

useEffect(
  ()=>{
    setIntro(<>
      <Backdrop/>
      </> )
  }, [])

function clickHome(){
  setIntro(<Backdrop/>)
  setIsActive(1)
}

function clickAbout(){
  setIntro(<AboutUs/>)
  setIsActive(2)
}

function gallery(){
  setIsActive(5)
  setIntro(<TheGallery value={thephoto}/>)
}


function contact(){
  setIsActive(7)
  setIntro(<div>UNDER CONSTRUCTION</div>)
}

function Navigate(){
  return(
    <div className="navbar">
      <ul>
          <li className="nav" ref={homebutton}  onClick={clickHome} id="nr1"  style={{color: isActive===1? "red": "green" }}> HOME </li>
          <li className="nav" onClick={clickAbout} id="nr2"  style={{color: isActive===2? "red": "green" }}> PALM OIL</li>
          <li className="nav" id="nr3"  style={{color: isActive===3? "red": "green" }}> KEY BUSINESS 
              <ul>
                <li className="nav nav2">Products
                      <ul>
                        <li className="nav nav3" onClick={()=>{setIntro(<div><img alt="SBR" src={photo1}/></div>); setIsActive(3)}}>product 1</li>
                        <li className="nav nav3" onClick={()=>{setIntro(<div><img alt="pump" src={photo2} /></div>);setIsActive(3)}} >product 2</li>
                        <li className="nav nav3" onClick={()=>{setIntro(<div><img alt="UASB" src={photo3} /></div>);setIsActive(3)}}>product 3</li>
                      </ul>
                </li>
                <li className="nav nav2" onClick={()=>{setIntro(<embed src="https://www.sciencedirect.com/topics/agricultural-and-biological-sciences/palm-oil" width="90%" height="900px"/>);setIsActive(3)}}>Research</li>
                <li className="nav nav2" onClick={()=>{setIntro(<embed src="https://tradingeconomics.com/commodity/palm-oil" width="90%" height="900px"/>);setIsActive(3)}}>Global Markets</li>
                </ul>
              </li>
          <li className="nav" onClick={gallery} id="nr3"  style={{color: isActive===5? "red": "green" }}> GALLERY</li>
          <li className="nav" onClick={contact} id="nr3"  style={{color: isActive===7? "red": "green" }}>Blogger </li>
      </ul>
    </div>
  )}

  return (
<>
<div className="container">
  <Header />
  <Navigate />
    {intro}
</div>
</>
  );
}

export default App;
